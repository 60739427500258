import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import UfoPage from './pages/UfoPage';
import UfosListPage from './pages/UfosListPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import NavBar from './NavBar';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div id="page-body">
          <Routes>
            <Route path="/" exact element={ <HomePage /> } /> 
            <Route path="/about" element={ <AboutPage /> } /> 
            <Route path="/ufos" exact element={ <UfosListPage /> } /> 
            <Route path="/ufos/:id" element={ <UfoPage /> } /> 
            <Route path="/ufos/:pageNumber/:perPage" element={ <UfosListPage /> } /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
//<Route> <NotFoundPage /> </Route>
//<Route path="/ufos" element={<UfosPage />}/>
//
//            <Route path="/ufos/:id/:searchTerm/:pageNumber/:perPage" element={ <UfoPage /> } /> 
