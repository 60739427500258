const ufos = [
    {
        id: '15632',
        description: 'Crazy. I was sitting outside on my porch in midtown manhattan, and i was drinking a beer and when i tiped the bottle up i saw in the sky these redish blueish things in the air i got my binoculers and there were these wierd shapes in the sky moving and spinning around. i called my girlfriend and showed her she fainted. it was just unbelievable!',
        duration: '',
        locationcity: 'New York City (Manhattan)',
        locationstate: 'NY',
        reportedat: '20020716',
        shape: 'disc',
        sightedatday: '20020716',
        sightedatmonth: '20020716',
        sightedatyear: '20020716'
    },    {
        id: '48189',
        description: 'I witnessed an abduction from my backyard.I was playing in my backyard at 6:00 and suddenly I saw something in the sky.  I watched the craft as it wobbled around then a light came out from it. The light was so bright that I had to turn away.  I looked at it again and it shot upwards with screaming coming out of it.',
        duration: '10 mins.',
        reportedat: '20071105',
        locationstate: 'CT',
        locationcity: 'Waterbury',
        shape: 'disk',
        sightedatday: '20071105',
        sightedatmonth: '20071105',
        sightedatyear: '20071105'
    },     {
        id: '435',
        description: 'Man repts. witnessing flash, followed by a classic UFO, w/ a tailfin at back. Red color on top half of tailfin. Became triangular.',
        duration: '',
        locationcity: 'City',
        locationstate: 'IA',
        reportedat: '19951006',
        shape: 'triangle',
        sightedatday: '19951009',
        sightedatmonth: '19951009',
        sightedatyear: '19951009'
    },     {
        id: '44905',
        description: 'i was abducted  i was walking along when 3 blob like crafts shone through the clouds then i was lifted from the ground then an hour later i was in bed but i rememberd it all exept what happend afgter being lifted((NUFORC Note:  Witness elects to remain totally anonymous;  provides no contact information.  PD))',
        duration: '1 hour',
        locationcity: 'Stockbridge',
        locationstate: 'GA',
        reportedat: '20071105',
        shape: 'changing',
        sightedatday: '20071105',
        sightedatmonth: '20071105',
        sightedatyear: '20071105'
    },  
];
export default ufos;

//    id              = BigIntegerField(default=datetime.datetime.now().strftime("%Y%m%d%H%M%S"))
//    description     = TextField()
//    duration        = CharField(max_length=255)
//    locationcity    = CharField(max_length=255)
//    locationstate   = CharField(max_length=255)
//    reportedat      = IntegerField()
//    shape           = CharField(max_length=255)
//    sightedatday    = IntegerField()
//    sightedatmonth  = IntegerField()
//    sightedatyear   = IntegerField()
