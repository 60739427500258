import UfosList from '../components/UfosList';
import ufos from './ufo-content';

const HomePage = () => (
    <>
        <h1>Hello, welcome to Ufosightings!</h1>
        <p>
        </p>
        <h3>Most Popular Ufosightings</h3>
        <UfosList ufos={ufos} />
    </>
);

export default HomePage;
