import { Link } from 'react-router-dom';

const UfosList = ({ ufos = [] , searchTerm="green", pageNumber = 1, perPage = 10 }) => (
    <>
    {ufos.map((ufo, key) => {
        //const info = ufoInfo.find(a => a.name === ufo.name);

        return (
            <Link key={key} className="article-list-item" to={`/ufos/${ufo.id}`}>
                <h3>{ufo.reportedat}</h3>
                <p>{ufo.description.substring(0, 150)}...</p>
            </Link>
        );
    })}
    </>
);

export default UfosList;
//20230421
//            <Link key={key} className="article-list-item" to={`/ufos/${ufo.id}/${searchTerm}/${pageNumber}/${perPage}`}>

//
//            const UfosList = ({ ufos = [] , searchTerm="green", pageNumber = 1, perPage = 10 }) => (
//
//            <Link key={key} className="article-list-item" to={`/ufos/${ufo.id}`}>
//


//    {comments.map((comment, key) => (
//        <div className="comment" key={key}>
//            <h4>{comment.postedBy}</h4>
//            <p>{comment.text}</p>
//        </div>
//    ))}
//
//
//    {ufos.map((ufo => {
//                <p>{ufo.description[0].substring(0, 15)}...</p>
//
//            <Link key={key} className="article-list-item" to={`/ufos/${ufo.id}`}>
//
//            <Link key={key} className="article-list-item" to={`/ufos/${pageNumber}/${perPage}`}>
