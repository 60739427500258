const AboutPage = () => {
    return (
    <>
        <h1>About Ufo Sightings</h1>
        <p>
        Names and other identifying information has been removed to protect the innocent from intergalactic retaliation. 
        </p>
    </>
    );
}

export default AboutPage;
